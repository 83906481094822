#thHunt {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  h1.header {
    overflow-x: auto;
    white-space: nowrap;
    width: 100vw;
  }
}
.LocationsCountIcons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.LocationsCountIcons .icon {
  transition: all ease-in-out 0.5s;
}
.LocationsCountIcons .todo {
  opacity: .25;
}
.LocationsCountIcons .active {
  transform: scale(1.2);
  opacity: 1;
}
.LocationsCountIcons .completed {
  opacity: .75;
}
.playerHeader {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  fill: url(#gradient);
}
.playerHeader stop:nth-child(1) {
  stop-color: rgba(255,0,0,1);
}
.playerHeader stop:nth-child(2) {
  stop-color: rgba(255,0,0,0);
}
.iconBrowser i {
  cursor: pointer;
}

.answerMap {
  flex: 1 1 auto;
  width: 100%;
  margin-top: 2px;
}

.huntMainContent > .card{
  max-width: 30rem;
  width: 95vw;
}
.huntMainFollowButtons {
  text-align: center;
}