#editorPage.mobile {
  .computerOnly {
    display: none;
  }
  .mobileNoMargin {
    margin: 0;
  }
  #header .right.menu {
    .button i {
      margin: 0;
    }
  }
}
#editorPage.computer {
  .mobileOnly {
    display: none;
  }
}

.helpText {
  color: #555;
  font-style: italic;
}
.huntInfoButton {
  margin: 0.75rem 0.25rem !important;
}

.iconBrowser {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.iconBrowser i {
  transition: box-shadow @defaultDuration @defaultEasing;
  margin: 0 0.5rem 1rem !important;
}
.iconBrowser i.activeIcon {
  box-shadow: 0 0 0 .1em @primaryColor inset !important;
}
.colourPicker {
  margin: 0 !important;
}
.colourPickerValue {
  width: 80% !important;
}
.colourPickerSwatchWrapper {
  line-height: 0;
}
.colourPickerSwatch {
  cursor: pointer;
  width: 10%;
  height: 2rem;
  display: inline-block;
}

.inline .ui.checkbox {
  display: inline-flex;
  vertical-align: middle;
}
.toggleTourShowOnStartup {
  position: absolute !important;
  bottom: 1.5rem;
  right: 30%;
}

.tourTip {
  border-left: 1rem solid @popColor;
  margin-left: -2rem;
  padding-left: 1rem;
  font-style: italic;
}

#browsePage,
#editorPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  grid-template-areas:
          "header"
          "editor";
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}
#browseHunts,
#editor {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
}
.sidebar {
  width: 40rem;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);
  z-index: 99;
  box-sizing: border-box;
  transition: ease-in-out all @defaultDuration;
  background: @white;
}
@media screen and (max-width: @largestMobileScreen) {
  #browseHunts {
    position: relative;
    .sidebar {
      position: absolute;
      width: 100%;
      left: 100%;
      top: 0;
      bottom: 0;
      &.shown {
        left: 0;
      }
    }
  }
}
#editorPage.mobile {
  #locationsAndDetails {
    width: 100%;
  }
}
#locationsAndDetails {
  overflow: hidden;
  padding-top: 1rem;
  .ui.menu {
    margin-bottom: 0;
  }
  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

#browseHunts .sidebar,
#locationsAndDetails > div > .segment {
  border: none;
  overflow-y: scroll;
  padding-bottom: 1rem;
  height: 100%;
  margin: 0;
}
#locationsAndDetails.advanced {
  .simpleOnly {
    display: none;
  }
  .ui.menu .item {
    i {
      margin: 0;
    }
  }
}


#editorPage.computer {
  /* this is the map icon, which is only needed on mobile */
  #locationsAndDetails .ui.menu .item:first-of-type {
    display: none;
  }
}
#editorPage.mobile {
  #locationsAndDetails .ui.menu .item {
    padding: 0.5rem 0.75rem;
  }
}

.map-wrapper {
  background: #CCC;
  grid-area: map;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.map-wrapper-mobile {
  padding: 1px 0 0 !important;
  margin: 0 !important;
}
.leaflet-container {
  height: 100%;
}

.leaflet-bar i {
  cursor: pointer;
  font-size: 1.25rem;
  margin: 0 !important;
}
.leaflet-control-locate.active > a {
  background: @primaryColor;
  color: #FFF;
}
#editorPage {
  counter-reset: markers;
}
.thMapMarker {
  font-size: 2.5rem !important;
  top: -2.7rem;
  position: relative;
  left: -1.04rem;
  color: @primaryColor;
  text-shadow: 0 0 1px #FFF;
  cursor: grab;
}
.thMapMarker.leaflet-drag-target {
  cursor: grabbing;
}
.thMapMarker.active {
  color: @popColor;
}
.thMapMarker::after {
  counter-increment: markers;
  content: counter(markers);
  position: absolute;
  font-family: @pageFont;
  font-size: 1.5rem;
  font-weight: bolder;
  top: -1rem;
}
.thMapMarkerSingle {
  cursor: grab;
  font-size: 2.5rem !important;
  top: -2.85rem;
  position: relative;
  left: -1.09rem;
  color: @primaryColor;
  text-shadow: 0 0 1px #FFF;
}
.thMapCenter {
  position: relative;
  font-size: 1rem !important;
  left: -0.15em;
  top: -0.3em;
}
.draggableListItem {
  overflow: hidden;
  height: 100%;
  padding: 0 1px !important;
  .ui[class*="top attached"].buttons {
    .button:first-child {
      border-radius: 0;
    }
    .button:last-child {
      border-radius: 0 @defaultBorderRadius @defaultBorderRadius 0;
    }
  }
}
.dragHandle {
  cursor: grab !important;
  position: absolute;
  top: 0;
  right: 1px;
  width: auto !important;
  padding: 0.5rem 0.25rem !important;
}
.activeLocationBox {
  border-left: 0.25rem solid @popColor !important;
}

.qrPopup {
  canvas {
    margin-bottom: 1rem;
    max-width: 100%;
    height: auto;
  }
  p {
    text-align: center;
  }
}

.ui.buttons .disabled.button, .ui.disabled.button, .ui.button:disabled, .ui.disabled.button:hover, .ui.disabled.active.button {
  pointer-events: auto !important;
}

#browseHuntsMessage {
  position: absolute;
  bottom: 0.5rem;
  z-index: 9999;
  display: flex;
  justify-content: center;
  left: 0.5rem;
  right: 0.5rem;
  font-size: 1rem;
}

.loader.loaderBottomRight {
  position: fixed;
  top: calc(100% - 2rem);
  left: calc(100% - 2rem);
}

.semantic_ui_range_inner {
  // Stupid module doesn't have any classes
  div:nth-last-of-type(2) {
    background: @primaryColor !important;
  }
  div:last-of-type {
    background: white !important;
    border: 1px solid @primaryColor !important;
  }
}
