html {
  font-size: @fontSize;
  @media screen and (max-width: @largestSmallMonitor) {
    font-size: @17px;
  }
  @media screen and (max-width: @largestTabletScreen) {
    font-size: @16px;
  }
}

body {
  background: #fbfbff;
  height: auto;
}

.troveLogo {
  fill: @popColor;
}

.accuracyCircle {
  fill: @secondaryColor;
}
.mapLine {
  stroke: @secondaryColor;
}
.playerLocation {
  stroke: @popColor;
}

.ui.dimmer {
  top: 0 !important;
  bottom: 0 !important;
  height: 100%;
}

@media screen and (max-width: @largestMobileScreen) {
  .computerOnly {
    display: none !important;
  }
}

@media screen and (min-width: @largestMobileScreen) {
  .mobileOnly {
    display: none !important;
  }
}

.troveLogo {
  height: 1.1em;
  width: auto;
}
h1 {
  .troveLogo {
    font-size: 2em;
  }
}

#thApp {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
}

#header {
  grid-area: header;
  z-index: 999;
  margin: 0;
  width: 100%;
}

#header,
#masthead {
  .troveLogoMenuItem {
    padding-bottom: 0;
    padding-top: 0;
  }
  .troveLogo {
    height: 1.8rem;
  }
  a, .item {
    font-weight: bold;
    @media screen and (min-width: @largestTabletScreen) {
      font-size: 1.1rem;
    }
  }
}

#header .ui.form > .inline.fields {
  margin-bottom: 0;
}

.loginFormWrapper {
  justify-content: center;
  flex: 1 1 auto;
  width: auto;
  margin: auto;
  max-width: 25rem;
  .ui.menu > a:last-child {
    margin-left: auto !important;
  }
}

hr {
  margin: 2rem 0;
  background: @lightGrey;
  border: none;
  height: 1px;
}

.loginFormWrapper > .column {
  max-width: calc(95vw);
  width: 30rem !important;
  height: 30rem;
}

.loginFormWrapper .tabular .item {
  flex: 1 1 auto !important;
  justify-content: center;
}

.buttonAsLink {
  box-shadow: none !important;
  background: none !important;
  font-weight: normal !important;
  padding: 0 !important;
  color: @primaryColor !important;
}

.buttonAsLink:hover {
  text-decoration: underline !important;
}

.ui.card > .button, .ui.card > .buttons, .ui.cards > .card > .button, .ui.cards > .card > .buttons {
  /* margin: 0 !important; */
}

#thHunt > .content {
  flex: 0 1 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0;
}

#thHunt > .huntMainContent {
  flex: 0 1 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem auto;
}

#thHunt > .header {
  text-align: center;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0.5rem;
}

#masthead > .item.dropdown {
  padding-top: 0;
  padding-bottom: 0;
}

#header,
#thHunt > .header,
#thHunt .footer,
#masthead,
.ui.mastfooter {
  background: rgba(255, 255, 255, .8);
  box-shadow: @subtleShadow;
  border-radius: 0 !important;
  margin: 0 !important;
  width: 100%;
  border: none;
  z-index: 900;
}

#thHunt .footer,
.ui.mastfooter {
  box-shadow: 0 -1px 2px 0 rgba(34,36,38,.15);;
}

#thHunt > h1.header {
  font-size: 1.75rem;
}

#page > #content {
  position: relative;
  flex: 1 1 auto;
  margin: 2rem auto;
  /*@media screen and (max-width: @largestMobileScreen) {
    margin: 1rem auto;
  }*/
}

#page {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
}

.main-container {
  width: 100%;
}

#primary {
  flex: 1 1 auto;
  font-size: 1.25rem;
}

article {
  width: 100%;
}

.lineBreaks {
  white-space: pre-line;
}

.loginForm {
  padding-bottom: 0 !important;
}

.ui.menu.tabular .item {
  padding: 0.5rem 1rem;
  flex: 1 1 auto;
  justify-content: center;
}

.huntPreviewMap {
  height: 100%;
  min-height: 25rem;
}

/*.browseMapWrapper {
  height: 30rem;
  margin-bottom: 2rem;
}*/

.map__reactleaflet {
  z-index: 0;
}

.ui.divider {
  clear: both;
}

.HuntStatsGrid.grid {
  padding: 0.5rem 0;
  .row {
    padding: 0.5rem 0;
  }
}

.background {
  background: #FFF;
  box-shadow: @subtleShadow;
  width: 20rem;
  height: 20rem;
  margin: auto;
  border-radius: 50%;
  display: block;
  z-index: -1;
  transition: opacity ease-in-out 1s;
}

.segment.compact {
  padding: 0;
}

.ui.action.input > .button, .ui.action.input > .buttons > .button {
  margin-left: -1px;
}

.ui.card.privacy-popup {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  left: 1rem;
  max-width: calc(100% - 2rem);
  width: 33rem;
}

.ui.loading::after,
.ui.loader::after {
  border-color: @popColor rgba(@black, 0.2) rgba(@black, 0.2) rgba(@black, 0.2) !important;
}